// IMAGES
import TEPlanLogo from '../../assets/te_plan.png';
import TEPreferencesLogo from '../../assets/te_preferences.png';
import TEDataManagerLogo from '../../assets/te_data_manager.png';
import TECoreLogo from '../../assets/te_core.png';
import TEExamLogo from '../../assets/te_exam.png';
import TEViewerLogo from '../../assets/te_viewer.png';
import TERegistrationLogo from '../../assets/te_registration.png';
import TEReserveLogo from '../../assets/te_reserve.png';
import TEReportingLogo from '../../assets/te_reporting.png';
import TEAutopilot from '../../assets/te_autopilot.png';
import TECurriculum from '../../assets/te_curriculum.png';
import TEEvaluation from '../../assets/te_evaluation.png';
import TEStudyguide from '../../assets/te_studyguide.png';
import TEAdminLogo from '../../assets/te_admin.png';

// CONSTANTS
import { TEProducts } from '../../types/product.type';

// STYLES
import './index.scss';

// TYPES
import { EAppId } from '@timeedit/types/lib/enums';
import { useMemo } from 'react';

type Props = {
  appId: EAppId;
  onClick: (appId: EAppId) => void;
};

const productImageMap: Record<EAppId, string | undefined> = {
  [EAppId.TE_PLAN]: TEPlanLogo,
  [EAppId.TE_PREFERENCES]: TEPreferencesLogo,
  [EAppId.TE_CORE]: TECoreLogo,
  [EAppId.TE_EXAM]: TEExamLogo,
  [EAppId.TE_VIEWER]: TEViewerLogo,
  [EAppId.TE_RESERVE]: TEReserveLogo,
  [EAppId.TE_REPORTING]: TEReportingLogo,
  [EAppId.TE_ADMIN]: TEAdminLogo,
  [EAppId.JAVA_CLIENT]: undefined,
  [EAppId.TE_SERVER_THREAD]: undefined,
  [EAppId.TE_SOAP]: undefined,
  [EAppId.TE_REGISTRATION]: TERegistrationLogo,
  [EAppId.TE_DATA_MANAGER]: TEDataManagerLogo,
  [EAppId.TE_REST]: undefined,
  [EAppId.TE_AM]: undefined,
  [EAppId.TE_AUTOPILOT]: TEAutopilot,
  [EAppId.TE_CURRICULUM]: TECurriculum,
  [EAppId.TE_EVALUATION]: TEEvaluation,
  [EAppId.TE_STUDY_GUIDE]: TEStudyguide,
  [EAppId.TE_SEAT_MANAGEMENT]: undefined,
  [EAppId.TE_ALLOCATE]: undefined,
};

const Product = ({ appId, onClick }: Props) => {
  const product = useMemo(
    () => TEProducts.find((p) => p.appId === appId),
    [appId],
  );

  if (!product) return null;

  return (
    <div className="product--wrapper" onClick={() => onClick(appId)}>
      <img
        className="product--logo"
        src={productImageMap[appId]}
        alt={product.name}
      />
      <div className="product--title">{product.name}</div>
    </div>
  );
};

export default Product;
