import { removeCloudPrefix } from '../utils/removeCloudPrefix.utils';
import { EAppId } from '@timeedit/types/lib/enums';
import { getRedirectUri } from '../services/config.service';

export interface IProduct {
  appId: EAppId;
  name: string;
  periodicNumber: number;
  bucket?: EProductBucket;
  redirectUrl: (customerSignature?: string) => string;
}

export enum EProductBucket {
  ADMINISTRATION = 'ADMINISTRATION',
  WORKFORCE_PLANNING = 'WORKFORCE_PLANNING',
  SCHEDULING_AND_RESOURCE_MANAGEMENT = 'SCHEDULING_AND_RESOURCE_MANAGEMENT',
  PUBLISHING_AND_REGISTRATION = 'PUBLISHING_AND_REGISTRATION',
  EVALUATION_AND_REPORTING = 'EVALUATION_AND_REPORTING',
  CURRICULUM_MANANGEMENT = 'CURRICULUM_MANANGEMENT',
}

export interface IProductBucket {
  bucket: EProductBucket;
  title: string;
  description: string;
}

export const TEProductBuckets: IProductBucket[] = [
  {
    bucket: EProductBucket.WORKFORCE_PLANNING,
    title: 'Workload Planning',
    description: 'Workforce management in tandem with curriculum & scheduling',
  },
  {
    bucket: EProductBucket.CURRICULUM_MANANGEMENT,
    title: 'Curriculum Management',
    description: 'Comprehensive curriculum design, mapping and planning',
  },
  {
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    title: 'Scheduling & Resource Management',
    description: 'Data collection, auto scheduling, manual scheduling in one unified process',
  },
  {
    bucket: EProductBucket.PUBLISHING_AND_REGISTRATION,
    title: 'Publishing & Registration',
    description: 'Timetable, study guide and student registration fully integrated',
  },
  {
    bucket: EProductBucket.EVALUATION_AND_REPORTING,
    title: 'Evaluation & Reporting',
    description: 'Make data-driven decisions to optimise how to drive student-centric outcomes',
  },
  {
    bucket: EProductBucket.ADMINISTRATION,
    title: 'Administration',
    description: 'Configure and manage your TimeEdit products and services',
  },

];

export const TEProducts: IProduct[] = [
  {
    appId: EAppId.TE_CURRICULUM,
    name: 'Curriculum',
    periodicNumber: 10,
    bucket: EProductBucket.CURRICULUM_MANANGEMENT,
    redirectUrl: (customerSignature) => getRedirectUri(EAppId.TE_CURRICULUM, customerSignature),
  },
  {
    appId: EAppId.TE_PLAN,
    name: 'Plan',
    periodicNumber: 1,
    bucket: EProductBucket.WORKFORCE_PLANNING,
    redirectUrl: (customerSignature) =>
      `https://cloud.timeedit.net/${removeCloudPrefix(
        customerSignature || '',
      )}/web`,
  },
  {
    appId: EAppId.TE_PREFERENCES,
    name: 'Preferences',
    periodicNumber: 2,
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    redirectUrl: () => getRedirectUri(EAppId.TE_PREFERENCES),
  },
  {
    appId: EAppId.TE_ALLOCATE,
    name: 'Allocate',
    periodicNumber: 12,
    redirectUrl: () => getRedirectUri(EAppId.TE_ALLOCATE),
  },
  {
    appId: EAppId.TE_DATA_MANAGER,
    name: 'Data Manager',
    periodicNumber: 9,
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    redirectUrl: () => getRedirectUri(EAppId.TE_DATA_MANAGER),
  },
  {
    appId: EAppId.TE_CORE,
    name: 'Core',
    periodicNumber: 3,
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    redirectUrl: (customerSignature) =>
      `https://cloud.timeedit.net/${removeCloudPrefix(
        customerSignature || '',
      )}/client`,
  },
  {
    appId: EAppId.TE_EXAM,
    name: 'Exam',
    periodicNumber: 4,
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    redirectUrl: () => getRedirectUri(EAppId.TE_EXAM),
  },
  {
    appId: EAppId.TE_VIEWER,
    name: 'Viewer',
    periodicNumber: 6,
    bucket: EProductBucket.PUBLISHING_AND_REGISTRATION,
    redirectUrl: (customerSignature) =>
      `https://cloud.timeedit.net/${removeCloudPrefix(
        customerSignature || '',
      )}/web`,
  },
  {
    appId: EAppId.TE_RESERVE,
    name: 'Reserve',
    periodicNumber: 7,
    bucket: EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT,
    redirectUrl: (customerSignature) =>
      getRedirectUri(
        EAppId.TE_RESERVE,
        removeCloudPrefix(customerSignature || ''),
      ),
  },
  {
    appId: EAppId.TE_STUDY_GUIDE,
    name: 'Study Guide',
    periodicNumber: 11,
    bucket: EProductBucket.PUBLISHING_AND_REGISTRATION,
    redirectUrl: (customerSignature) => getRedirectUri(EAppId.TE_STUDY_GUIDE, customerSignature), // TODO: Add redirect URL?
  },
  {
    appId: EAppId.TE_EVALUATION,
    name: 'Evaluation',
    periodicNumber: 12,
    bucket: EProductBucket.EVALUATION_AND_REPORTING,
    redirectUrl: (customerSignature) => getRedirectUri(EAppId.TE_EVALUATION, customerSignature), // TODO: Add redirect URL?
  },
  {
    appId: EAppId.TE_REPORTING,
    name: 'Reporting',
    periodicNumber: 8,
    bucket: EProductBucket.EVALUATION_AND_REPORTING,
    redirectUrl: () => getRedirectUri(EAppId.TE_REPORTING),
  },
  {
    appId: EAppId.TE_REGISTRATION,
    name: 'Registration',
    periodicNumber: 10,
    bucket: EProductBucket.PUBLISHING_AND_REGISTRATION,
    redirectUrl: () => getRedirectUri(EAppId.TE_REGISTRATION),
  },
  {
    appId: EAppId.TE_ADMIN,
    name: 'Admin',
    periodicNumber: 999,
    bucket: EProductBucket.ADMINISTRATION,
    redirectUrl: () => getRedirectUri(EAppId.TE_ADMIN),
  },
];
