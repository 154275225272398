import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

// HOOKS
import { useParsedQuery } from '../../hooks/useQuery';

// REDUX
import { organizationSelector } from '../../slices/organization.slice';

// SERVICES
import { getProductForAppId } from '../../services/product.service';
import { setAuthToken } from '../../services/token.service';

// STYLES
import './index.scss';

// TYPES
import { EAppId } from '@timeedit/types/lib/enums';

const SuccessfulAuthPage = () => {
  const [organizationId, appId, context, token] = useParsedQuery(['organizationId', 'appId', 'context', 'token']);
  const organization = useSelector(organizationSelector(organizationId || ''));
  const product = appId ? getProductForAppId(appId as EAppId) : null;

  setTimeout(() => {
    // If we have a token in the URLParams, make sure we set it
    if (token && token !== 'null') {
      setAuthToken(token)
    }

    // Redirect to wherever the user is going
    const redirectUrl = product?.redirectUrl(organization.customerSignature) || '';
    if (redirectUrl && context?.toLowerCase().startsWith(redirectUrl?.toLowerCase())) {
      window.location.href = context;
    }
    else if (redirectUrl && context?.startsWith('/')) {
      window.location.href = `${redirectUrl}${context}`;
    } else {
      window.location.href = redirectUrl;
    }
  }, 500);

  return (
    <div className="successful-auth--wrapper">
      <Spin tip={
        <>
          <span>You will be redirected automatically. If you are not being redirected, click </span>
          <a className="redirect-link" href={product?.redirectUrl(organization?.customerSignature)}>here</a>
        </>
      } indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  )
};

export default SuccessfulAuthPage;
