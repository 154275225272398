import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { getAuthToken, hasTokenExpired } from '../../services/token.service';

// COMPONENTS
import { IdPAuthConfigButton } from '../../components/AuthConfigButton/idpAuthConfigButton';
import { SSOAuthConfigButton } from '../../components/AuthConfigButton/ssoAuthConfigButton';
import LoadingSpinner from '../../components/LoadingSpinner';

// REDUX
import {
  authConfigsLoading,
  authConfigsSelector,
  fetchAuthConfigsForOrganizationAndApp,
} from '../../slices/authConfig.slice';
import { organizationSelector } from '../../slices/organization.slice';

// STYLES
import './index.scss';

// TYPES
import { EAppId } from '@timeedit/types/lib/enums';
import { EAuthStrategy } from '../../types/authConfig.type';

const appIdMap: Record<string, EAppId> = {
  [EAppId.TE_ALLOCATE]: EAppId.TE_DATA_MANAGER,
};

const AuthSelectionPage = () => {
  const { organizationId, appId }: { organizationId: string, appId: EAppId } = useParams();
  const dispatch = useDispatch();

  // SELECTORS
  const organization = useSelector(organizationSelector(organizationId));
  const authConfigs = useSelector(authConfigsSelector);
  const isLoading = useSelector(authConfigsLoading);
  const token = getAuthToken();
  const history = useHistory();

  useEffect(() => {
    if (
      !hasTokenExpired(token) &&
      appId !== undefined &&
      organization?.region !== undefined &&
      token !== undefined &&
      token !== null
    ) {
      const searchParams = new URLSearchParams();
      searchParams.set("organizationId", organizationId);
      searchParams.set("appId", appIdMap[appId] ?? appId);
      searchParams.set("token", token);
      searchParams.set("region", organization.region);
      const searchParamsString = searchParams.toString();
      history.push(
        `/successful-authentication${searchParamsString ? `?${searchParamsString}` : ""}`,
      );
    }
  }, [history, appId, organization?.region, organizationId, token]);

  useEffect(() => {
    if (organization) {
      dispatch(fetchAuthConfigsForOrganizationAndApp(organization.id, appIdMap[appId] ?? appId, organization.region));
    }
  }, [dispatch, organization, appId]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className="auth-selection--wrapper">
        <Typography.Title level={2} style={{ marginBottom: 0 }}>Sign in.</Typography.Title>
        <Typography.Paragraph style={{ marginBottom: '2rem' }}>Select your authentication method to
          start</Typography.Paragraph>
        {authConfigs
          .filter((authConfig) => authConfig.strategy !== EAuthStrategy.TE_IDP)
          .map((authConfig) => (
            <SSOAuthConfigButton key={authConfig.id} authConfig={authConfig} />
          ))}
        <div style={{ marginBottom: "2rem" }}></div>
        {authConfigs
          .filter((authConfig) => authConfig.strategy === EAuthStrategy.TE_IDP)
          .map((authConfig) => (
            <IdPAuthConfigButton key={authConfig.id} authConfig={authConfig} region={organization?.region} />
          ))}
      </div>
    </LoadingSpinner>
  );
};

export default AuthSelectionPage;
